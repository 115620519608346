.quill-editor-container {
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    border: none; /* Remove container border */
    padding: 0; /* Remove container padding */
}

.ql-toolbar.ql-snow {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
    border: none; /* Remove toolbar border */
    border-bottom: 1px solid #ccc; /* Optional: add a bottom border to distinguish toolbar */
}

.quill-editor .ql-container {
    flex: 1;
    overflow-y: auto;
    height: calc(100% - 42px); /* Adjust height to account for the toolbar */
    border: none; /* Remove editor container border */
    padding: 0; /* Remove editor container padding */
}

.ql-container.ql-snow {
    border: none; /* Remove border from the editor container */
}

.ql-editor {
    padding: 15px; /* Add padding to the editor content area */
    min-height: 100%; /* Ensure editor takes full height */
    box-sizing: border-box; /* Ensure padding does not affect total height */
}

.quill-editor{
    max-height: 100vh;
}

.highlight {
  background-color: #fff3b0;
  padding: 2px;
  border-radius: 2px;
}

.current-match {
  background-color: #ffd700;
  border: 2px solid #ff8c00;
}



@keyframes loading {
    0% { content: ''; }
    25% { content: '.'; }
    50% { content: '..'; }
    75% { content: '...'; }
}

/* Editor specific scrollbar styles */
.editor-js-wrapper {
    max-height: 70vh;
    overflow-y: scroll;
    padding: 1rem;
    overflow-x: hidden;
    -ms-overflow-style: auto;  /* Match custom-scrollbar */
    scrollbar-width: auto;  /* Match custom-scrollbar */
}

.editor-js-wrapper::-webkit-scrollbar {
    width: 5px;
    display: flex;  /* Match custom-scrollbar */
}

.editor-js-wrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.editor-js-wrapper::-webkit-scrollbar-thumb {
    background: #026277;
    border-radius: 6px;
}

.editor-js-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Remove the Firefox specific override since we're matching custom-scrollbar */
/* Remove the system-level override since we don't need it */
