.pdf-uploader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
  gap: 20px;
}

.file-input {
  margin-bottom: 20px;
  padding: 10px;
  border: 2px dashed #ccc;
  border-radius: 4px;
  width: 300px;
  text-align: center;
  cursor: pointer;
}

.pdf-viewer-container {
  width: 100%;
  max-width: 800px;
  height: 10000px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f5f5f5;
  position: relative;
}

.pdf-content {
  height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #6BBDBD #f1f1f1;
  padding: 20px 0;
}

.controls-container {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: transparent;
  padding: 8px 20px;
  border-bottom: none;
  display: flex;
  justify-content: flex-end;
}

.zoom-controls {
  background-color: white;
  border-radius: 24px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Fira Sans';
  color: #666;
  font-size: 14px;
  border: 1px solid #E2F1F1;
}

.zoom-controls button {
  padding: 6px;
  color: #6BBDBD;
}

.zoom-controls button:hover {
  background-color: #E2F1F1;
}

.pdf-content::-webkit-scrollbar {
  width: 8px;
}

.pdf-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.pdf-content::-webkit-scrollbar-thumb {
  background: #6BBDBD;
  border-radius: 4px;
}

.pdf-content::-webkit-scrollbar-thumb:hover {
  background: #5AACAC;
}

.pdf-document {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.pdf-page {
  margin: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  position: relative;
}

/* Style for the text layer */
.react-pdf__Page__textContent {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  user-select: text !important;
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="%236BBDBD"><path d="M16.5,10L14,7.5L3,18.5V21H5.5L16.5,10M16.5,7.5L18.5,5.5C19.2,4.8 19.2,3.7 18.5,3L16,0.5C15.3,-0.2 14.2,-0.2 13.5,0.5L11.5,2.5L16.5,7.5M9,15C9,15 9,15 9,15.1C9,15 9,15 9,15V15Z"/></svg>') 8 24, text !important;
}

.react-pdf__Page__textContent > span {
  position: absolute;
  white-space: pre;
  transform-origin: 0% 0%;
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="%236BBDBD"><path d="M16.5,10L14,7.5L3,18.5V21H5.5L16.5,10M16.5,7.5L18.5,5.5C19.2,4.8 19.2,3.7 18.5,3L16,0.5C15.3,-0.2 14.2,-0.2 13.5,0.5L11.5,2.5L16.5,7.5M9,15C9,15 9,15 9,15.1C9,15 9,15 9,15V15Z"/></svg>') 8 24, text !important;
  color: transparent;
  user-select: text !important;
}

.react-pdf__Page__textContent::selection,
.react-pdf__Page__textContent > span::selection {
  background-color: rgba(107, 189, 189, 0.3) !important;
}

/* Override any default selection color */
::selection {
  background-color: rgba(107, 189, 189, 0.3) !important;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  gap: 16px;
}

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  gap: 16px;
}

.error-message {
  color: #d32f2f;
  margin-bottom: 16px;
} 