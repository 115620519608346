.pdf-viewer-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: absolute;
  inset: 0;
}

.pdf-content {
  flex: 1;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}

.controls-container {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  padding: 8px;
  border-bottom: 1px solid #E2F1F1;
  display: flex;
  justify-content: flex-end;
}

.zoom-controls {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  background-color: #F8F9FA;
  border-radius: 20px;
  font-family: 'Fira Sans';
  color: #666;
  font-size: 14px;
}

.pdf-document {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  flex: 1;
  width: 100%;
  min-height: 100%;
}

.pdf-page {
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  height: auto;
}

.pdf-page:last-child {
  margin-bottom: 0;
}

/* Style for the text layer to make text selectable but invisible */
.react-pdf__Page__textContent {
  user-select: text !important;
  color: transparent !important;
  pointer-events: none;
}

.react-pdf__Page__textContent span {
  pointer-events: auto;
  cursor: text;
}

.react-pdf__Page__textContent span::selection {
  background: rgba(107, 189, 189, 0.3); /* Custom selection color */
}

.react-pdf__Document {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
} 